<template>
  <section class="cont marketing-members-cont">
    <!-- 面包屑 -->
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <!-- 查询框 -->
      <el-row class="search-box">
        <el-row class="search-row">
          <el-button type="primary" @click="addHelpEntry()">新增帮助条目</el-button>
          <el-button type="primary" @click="getCustomerConfiguration()">客服配置</el-button>
        </el-row>
      </el-row>
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格 -->
        <el-table  fit :data="tableData" :loading="tableLoad">
          <el-table-column label="序号" type="index" width="60"></el-table-column>
          <el-table-column prop="title" label="帮助标题"></el-table-column>
          <el-table-column label="帮助指向链接">
            <template slot-scope="scope">
              <el-link type="primary" :underline="false" :href="scope.row.url" target="_blank">{{scope.row.url}}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              {{scope.row.state == 'YES' ? '显示' : '隐藏'}}
            </template>
          </el-table-column>
          <el-table-column label="排序" width="150">
            <template slot-scope="scope">
              <el-row>
                <el-col :span="16">
                  <span v-if="!scope.row.isSort">{{scope.row.sortNo}}</span>
                  <input type="number" v-model="sortNo" style="width:70%" v-else>
                </el-col>
                <el-col :span="8">
                  <i class="el-icon-edit-outline font-18" @click="sortData(scope.row.id)" v-if="!scope.row.isSort"></i>
                  <span v-else>
                    <i class="el-icon-close c-red font-18" @click="scope.row.isSort = false"></i>
                    <i class="el-icon-check m-left-5 c-blue font-18" @click="sort(scope.row)"></i>
                  </span>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="addHelpEntry(scope.row)">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="deleteHelpEntry(scope.row.id)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
           </el-table-column>
          </el-table>
        <!-- 分页 -->
        <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
      </el-row>


      <!-- 新增帮助条目 -->
      <el-dialog :title="title" :visible.sync="dialogAddHelpVisible" width="600px">
        <el-form>
          <el-form-item label="帮助标题" required>
            <el-input placeholder="请输入标题" v-model="helpForm.title" maxlength="30" style="width: 400px" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="指向链接" required>
            <el-input placeholder="请输入链接" v-model="helpForm.url" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="显示状态" required>
            <el-radio v-model="helpForm.state" label="YES">显示</el-radio>
            <el-radio v-model="helpForm.state" label="NO">隐藏</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAddHelpVisible = false">取 消</el-button>
          <el-button type="primary" @click="add()">确 认</el-button>
        </div>
      </el-dialog>

       <!-- 客服配置 -->
      <el-dialog title="客服配置" :visible.sync="dialogCustomVisible" width="600px">
        <el-form>
          <el-form-item label="联系电话" required>
            <el-input placeholder="请输入电话" v-model="customForm.mobile" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="售后客服" required>
            <el-input placeholder="请输入客服电话" v-model="customForm.afterSalesTelephone" style="width: 400px"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogCustomVisible = false">取 消</el-button>
          <el-button type="primary" @click="setCustomerConfiguration()">确 认</el-button>
        </div>
      </el-dialog>
    </el-row>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { getPage, add, edit, deleteData, getCustomerConfiguration, setCustomerConfiguration } from '@/api/system/hotelHelpCenter.js'
  export default {
    data() {
      return {
        crumbs: new Map([['系统管理'], ['酒店配置管理']]),
        that: this,
        tableData: [], // 表格用户列表
        tableLoad: true, // 表格加载效果
        limit: 10, // 每页数
        page: 1, // 当前页
        total: 0, // 用户列表总条目数

        /* 标签管理 */
        dialogAddHelpVisible: false, // 新增、修改帮助条目弹窗
        helpForm: {}, // 新增、修改帮助条目数据
        title: '新增帮助条目',

        // 客服配置
        dialogCustomVisible: false,
        customForm: {}, //售后客服

        sortNo: 0,
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
     this.getData()
    },
    methods: {
      getData() {
        this.tableLoad = true
        getPage({limit: this.limit, page: this.page}).then(res => {
          this.tableData = res.records
          this.total = res.total
          this.tableLoad = false
        }) 
      },

      // 新增帮助条目 
      addHelpEntry(data) {
        this.helpForm = {state: 'YES', sortNo: 1}
        if (data) {
          this.title = '编辑帮助条目'
          this.helpForm = {...data}
        } else {
          this.title = '新增帮助条目'
        }
        this.dialogAddHelpVisible = true
      },

      // 确定新增/编辑帮助条目
      add() {
        if (!this.helpForm.title) {
          this.$message.error('请填写标题')
          return
        }

        if (!this.helpForm.url) {
           this.$message.error('请填写帮助指向链接')
           return
        }

       if (!this.helpForm.id) {
         add(this.helpForm).then(res => {
          if (res.success) {
            this.$message.success(`${this.title}成功`)
            this.getData()
          }
          this.dialogAddHelpVisible = false
        })
       } else {
        edit(this.helpForm).then(res => {
          if (res.success) {
            this.$message.success(`${this.title}成功`)
            this.getData()
          }
          this.dialogAddHelpVisible = false
        })
       }
      },

      // 删除帮助条目
      deleteHelpEntry(id) {
         this.$confirm('是否确认删除该条目，将无法显示', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          deleteData(id).then(res => {
           this.$message.success('删除成功')
           this.getData()
          })
        })
      },

      // 获取客服配置
      getCustomerConfiguration() {
        getCustomerConfiguration().then(res => {
          this.customForm = res.records
          this.dialogCustomVisible = true;
        })
      },

      // 修改客服配置
      setCustomerConfiguration() {
        let patt = /[\u4e00-\u9fa5]/
        if (patt.test(this.customForm.mobile) || patt.test(this.customForm.afterSalesTelephone)) {
          this.$message.error('电话不能带有中文')
          return
        }

        if (!this.customForm.mobile) {
          this.$message.error('请填写联系电话')
          return
        }
       
        if (!this.customForm.afterSalesTelephone) {
          this.$message.error('请填写售后客服电话')
          return
        }

        setCustomerConfiguration(this.customForm).then(res => {
          if (res.success) {
            this.dialogCustomVisible = false
            this.$message.success('配置成功')
          }
        })
      },

      sortData(id) {
        for (let i in this.tableData) {
          if (this.tableData[i].id == id) {
            this.$set(this.tableData[i], 'isSort', true)
            this.sortNo = this.tableData[i].sortNo
          } else {
             this.$set(this.tableData[i], 'isSort', false)
          }
        }
      },

       sort(data) {
         data.sortNo = this.sortNo * 1
         edit(data).then(res => {
          if (res.success) {
            this.$message.success('修改排序成功')
            this.getData()
          }
        })
       },

      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getData()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getData()
      },
    },
    watch: {
      hotelInfo(newVal, oldVal) {
        if (newVal.id !== oldVal.id && oldVal) {
          this.getData()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  i {
    cursor: pointer;
  }
</style>

